
import './init.css'

export const InitSpin = ()=>{
    return(<>
        

        <div>
            <div className='init'>

            </div>
            <p className='sam_abs' style={{
                color:'#222', 
            fontSize:'15.6px', 
            wordSpacing:'1px',
            letterSpacing:'1px'
            }}>Loading files...</p>

        </div>


        
    </>)
};